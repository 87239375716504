import React from 'react';
import styles from './AppHeader.module.css';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
const AppHeader = () => {
    return (
        <div className={styles.container}>
            <Logo/>
        </div>
    );
};

export default AppHeader;