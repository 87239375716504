import React, {useRef} from 'react';
import styles from './InputError.module.css';
import {ReactComponent as ErrorIcon} from '../assets/images/error-icon.svg';
import Tooltip from './Tooltip';
const InputError = ({message, style}) => {
    return (
        <div style={style} className={styles.wrapper}>
            <div className={styles.iconContainer} tabIndex={0}>
                <div className={styles.iconBg}/>
                <ErrorIcon/>
            </div>
            <Tooltip className={styles.tooltip} message={message} style={{bottom: 'calc(100% - 12px)'}} triangleWrapperStyle={{justifyContent: 'flex-end', paddingRight: '15px'}}/>
        </div>
    );
};

export default InputError;