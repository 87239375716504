import React, {useContext, useEffect} from 'react';
import {ClientDataContext} from '../context/client-data';
import {UserInputDataContext} from '../context/user-input-data';
import {incomeStepInputs, pickInputs} from '../utils/inputs';
import FormInputSet from './FormInputSet';
import RadioInput from './RadioInput';
import {InternalStepStateContext} from '../context/internal-step-state-context';
import incomeSubstepTypes from '../types/income-substeps';
import {formatPhone} from '../utils/format';
import Input from './Input';

const IncomeEditingForm = ({failedInputs, currentSubStep}) => {
    const {clientData} = useContext(ClientDataContext);
    const {userInputData, setUserInputData} = useContext(UserInputDataContext);
    const {internalStepState, setInternalStepState} = useContext(InternalStepStateContext);

    const mainPhoneNumber = clientData?.interbankData?.phoneSmsVerification || clientData?.interbankData?.sessionInfoAuthPhone;

    const userHasAdditionalIncome = internalStepState.userHasAdditionalIncome;
    const setUserHasAdditionalIncome = (value) => {
        setInternalStepState(prevState => ({...prevState, userHasAdditionalIncome: value}));
    };

    const userOwnsProperty = internalStepState.userOwnsProperty;
    const setUserOwnsProperty = (value) => {
        setInternalStepState(prevState => ({...prevState, userOwnsProperty: value}))
    };

    const userOwnsCar = internalStepState.userOwnsCar;
    const setUserOwnsCar = (value) => {
        setInternalStepState(prevState => ({...prevState, userOwnsCar: value}))
    };

    const userIsContractWorker = internalStepState.userIsContractWorker;
    const setUserIsContractWorker = (value) => {
        setInternalStepState(prevState => ({...prevState, userIsContractWorker: value}))
    };

    const handleAdditionalIncomeChangeFromRadio = ({value}) => {
        if(value === userHasAdditionalIncome)
            return;
        setUserHasAdditionalIncome(value);
        if(value){
            setUserInputData(prev => ({...prev,
                incomeAdditionalSource: clientData.incomeAdditionalSource,
                incomeAdditionalAmount: clientData.incomeAdditionalAmount})
            );
        }else{
            setUserInputData(prev => ({...prev, incomeAdditionalSource: null, incomeAdditionalAmount: null}));
        }
    };

    const handleOwnedCarChangeFromRadio = ({value}) => {
        if(value === userOwnsCar)
            return;
        setUserOwnsCar(value);
        if(value){
            setUserInputData(prev => ({...prev,
                ownedCarBrand: clientData.ownedCarBrand,
                ownedCarYear: clientData.ownedCarYear})
            );
        }else{
            setUserInputData(prev => ({...prev, ownedCarBrand: null, ownedCarYear: null}));
        }
    };

    const handleOwnedPropertyChangeFromRadio = ({value}) => {
        if(value === userOwnsProperty)
            return;
        setUserOwnsProperty(value);
        if(value){
            setUserInputData(prev => ({...prev, ownedProperty: clientData.ownedProperty}));
        }else{
            setUserInputData(prev => ({...prev, ownedProperty: null}));
        }
    };

    const handleUserIsContractWorkerChangeFromRadio = ({value}) => {
        if(value === userIsContractWorker)
            return;
        setUserIsContractWorker(value);
        if(value){
            setUserInputData(prev => ({...prev, jobContractEndDate: clientData.jobContractEndDate}));
        }else{
            setUserInputData(prev => ({...prev, jobContractEndDate: null}));
        }
    };

    useEffect(() => {

        const hasAdditionalIncome = !!userInputData?.incomeAdditionalSource?.trim() || !!userInputData?.incomeAdditionalAmount;
        setUserHasAdditionalIncome(hasAdditionalIncome);

        const userOwnsProperty = !!userInputData?.ownedProperty?.length;
        setUserOwnsProperty(userOwnsProperty);

        const userOwnsCar = !!userInputData?.ownedCarBrand?.trim() || !!userInputData?.ownedCarYear?.toString()?.trim();
        setUserOwnsCar(userOwnsCar);

        const userIsContractWorker = !!userInputData?.jobContractEndDate?.trim();
        setUserIsContractWorker(userIsContractWorker);

        if (userInputData?.maritalStatus !== 'MARRIED') {
            setUserInputData(prev => ({...prev, spouseIncomeMainAmount: null}));
        }
    }, []);

    useEffect(() => {
        if (userInputData?.maritalStatus !== 'MARRIED') {
            setUserInputData(prev => ({...prev, spouseIncomeMainAmount: null}));
        }
    }, [userInputData?.maritalStatus]);

    return (
        <div>
            {currentSubStep === incomeSubstepTypes.FAMILY && (
                <FormInputSet
                    inputGroups={[
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['maritalStatus', 'drivingExperience']),
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['ownedProperty']),
                            useConditionalRender: true,
                            renderCondition: userOwnsProperty,
                            groupChildren: <RadioInput
                                            value={userOwnsProperty}
                                            label={'Имеете ли недвижимое имущество в собственности?'}
                                            name={'ownedPropertySwitch'}
                                            onChangeCallback={handleOwnedPropertyChangeFromRadio}/>
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['ownedCarBrand', 'ownedCarYear']),
                            useConditionalRender: true,
                            renderCondition: userOwnsCar,
                            groupChildren: <RadioInput
                                            value={userOwnsCar}
                                            label={'Имеете ли в собственности автомобиль?'}
                                            name={'ownedCarSwitch'}
                                            onChangeCallback={handleOwnedCarChangeFromRadio}/>
                        },
                    ]}
                    failedInputs={failedInputs}/>
            )}
            {currentSubStep === incomeSubstepTypes.JOB && (
                <FormInputSet
                    inputGroups={[
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['incomeMainType', 'jobPlace']),
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['jobCompanyAddress']),
                            useConditionalRender: true,
                            renderCondition: !!userInputData?.jobPlace?.trim()
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['jobPhoneNumber', 'jobTitle', 'jobStartDate']),
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['jobContractEndDate']),
                            useConditionalRender: true,
                            renderCondition: userIsContractWorker,
                            groupChildren: <RadioInput
                                            value={userIsContractWorker}
                                            label={'Работаете по контракту (трудовому договору)?'}
                                            name={'userIsContractWorkerSwitch'}
                                            onChangeCallback={handleUserIsContractWorkerChangeFromRadio}/>
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['incomeMainAmount']),
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['spouseIncomeMainAmount']),
                            useConditionalRender: true,
                            renderCondition: userInputData?.maritalStatus === 'MARRIED',
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['incomePartTimeAmount', 'incomeContractsAmount', 'pensionOrOtherIncomeAmount']),
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['incomeAdditionalSource', 'incomeAdditionalAmount']),
                            useConditionalRender: true,
                            renderCondition: userHasAdditionalIncome,
                            groupChildren: <RadioInput
                                            value={userHasAdditionalIncome}
                                            label={'Наличие дополнительного неподтвержденного дохода'}
                                            name={'additionalIncomeSwitch'}
                                            onChangeCallback={handleAdditionalIncomeChangeFromRadio}/>
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['totalWorkingExperience', 'higherEducation', 'dependentsAmount',
                                'loanPaymentsAmount', 'installmentPaymentsAmount', 'executiveOrderPaymentsAmount', 'alimonyPaymentsAmount']),
                        },
                    ]}
                    failedInputs={failedInputs}/>
            )}
            {currentSubStep === incomeSubstepTypes.CONTACTS && (
                <FormInputSet
                    inputGroups={[
                        {
                            inputs: [],
                            groupChildren: <Input value={formatPhone(mainPhoneNumber)} label={'Мобильный телефон'} disabled/>
                        },
                        {
                            inputs: pickInputs(incomeStepInputs[currentSubStep], ['emergencyPhoneNumber', 'additionalPhoneNumber', 'email']),
                        }
                    ]}
                    failedInputs={failedInputs}/>
            )}
        </div>
    );
};

export default IncomeEditingForm;