import React, {useContext} from 'react';
import styles from './FormInputSet.module.css';
import Input from './Input';
import {UserInputDataContext} from '../context/user-input-data';
import Select from './Select';
const FormInputSet = ({inputGroups, failedInputs}) => {
    const {userInputData, setUserInputData} = useContext(UserInputDataContext);

    const handleInputChange = ({name, value}) => {
        setUserInputData(prev => {
            let updatedData = {...prev};
            updatedData[name] = value;
            return updatedData;
        });
    };

    const resetError = (name) => {
        if(failedInputs?.[name]){
            delete failedInputs[name];
        }
    };

    const generateInput = (input, fieldName) => {
        return <Input key={fieldName}
                      value={userInputData !== null ? (userInputData[fieldName] || '') : ''}
                      label={input.label}
                      name={fieldName}
                      required={input.required || false}
                      onChangeCallback={handleInputChange}
                      error={failedInputs?.[fieldName]?.message}
                      type={input.type || 'text'}
                      maxLength={input.maxLength}
                      resetError={() => resetError(fieldName)}
        />
    };

    const generateSelect = (select, fieldName) => {
        return <Select key={fieldName}
                       optionTypes={select.optionTypes}
                       value={userInputData !== null ? (userInputData[fieldName] || '') : ''}
                       label={select.label}
                       name={fieldName}
                       required={select.required || false}
                       isMultiSelect={select.isMultiSelect}
                       onChangeCallback={handleInputChange}
                       error={failedInputs?.[fieldName]?.message}
                       resetError={() => resetError(fieldName)}
        />
    };

    return (
        <div>
            {inputGroups.map((group, groupIndex) => {
                return <div className={styles.inputsContainer} key={groupIndex}>
                    {group.groupChildren}
                    {!group.useConditionalRender || (group.useConditionalRender && group.renderCondition) ?
                        (Object.keys(group.inputs).map((fieldName) => {
                                const input = group.inputs[fieldName];
                                if(input.type !== 'select')
                                    return generateInput(input, fieldName);
                                else
                                    return generateSelect(input, fieldName);
                            }))
                        : ''
                    }
                </div>
            })}
        </div>
    );
};

export default FormInputSet;