import React from 'react';
import styles from './NotFoundPage.module.css';
import {Link, useLocation} from 'react-router-dom';
const NotFoundPage = () => {
    const location = useLocation();
    const requestId = location?.state?.requestId;
    const redirectedFrom = location?.state?.from;
    const redirectedFromFull = location?.state?.fromFull;
    const buildRequestUrl = (requestId) => {
        return `${window.location.protocol}//${window.location.host}/${requestId}`;
    };
    const shortenDisplayedLink = (link) => {
        if (link?.length > 128){
          return `${link.slice(0, 63)}...${link.slice(-62)}`
        }else
            return link;
    };
    return (
        <div className={styles.wrapper}>
            <div>
                <h1 className={styles.title}>
                    <div className={styles.digit}>4</div>
                    <div className={styles.emoji}>🔎</div>
                    <div className={styles.digit}>4</div>
                </h1>
                <h3 className={styles.subtitle}>{requestId ? 'Заявка' : 'Страница'} не найдена</h3>
                <p className={styles.text}>
                    {requestId ? 'Ссылка была скопирована некорректно, либо заявка была удалена.' : 'Введенного пути не существует. Возможно, ссылка была скопирована некорректно.'}
                </p>
                {requestId ?
                    <div className={styles.text}>
                        <div className={styles.failedLinkTitle}>Вы пытались открыть заявку по ссылке:</div>
                        <a href={buildRequestUrl(requestId)} className={styles.failedLink}>{shortenDisplayedLink(redirectedFromFull)}</a>
                    </div>
                    : ''}
                {redirectedFrom && redirectedFromFull ?
                    <div className={styles.text}>
                        <div className={styles.failedLinkTitle}>Вы пытались открыть страницу по следующему пути:</div>
                        <Link className={styles.failedLink} to={redirectedFrom}>{shortenDisplayedLink(redirectedFromFull)}</Link>
                    </div>
                    : ''}
            </div>
        </div>
    );
};

export default NotFoundPage;