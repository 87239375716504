import React from 'react';
import styles from './Button.module.css';

const Button = ({children, onClick, disabled, type, filled = true, className, style}) => {
    return (
        <button style={style}
                className={`${styles.button} ${filled ? styles.filled : styles.outlined} ${className}`}
                disabled={disabled} onClick={onClick} type={type}>{children}</button>
    );
};

export default Button;