import React, {useContext} from 'react';
import styles from './DocumentsReview.module.css';
import {ClientDataContext} from '../context/client-data';
import DocumentFileItem from './DocumentFileItem';

const DocumentsReview = () => {
    const {clientData} = useContext(ClientDataContext);
    return (
        <div className={styles.container}>
            {clientData?.documentFiles.map((doc, index) =>
                <DocumentFileItem fileName={doc.name}
                                  filePath={doc.path}
                                  accepted={doc.isSigned}
                                  disabled={true}
                                  actionDownload={true}
                                  key={index}
                />
            )}
            <DocumentFileItem fileName={'Согласие на рекламно-информационную рассылку об услугах А-Лизинг'}
                              accepted={clientData?.userInput?.adsAllowed}
                              disabled={true}
                              actionDownload={true}
                              useApiRootPrefix={false}
                              filePath={`${process.env.PUBLIC_URL}/personal-data-processing-acceptance.pdf`}
            />
        </div>
    );
};

export default DocumentsReview;