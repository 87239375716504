import styles from './App.module.css';
import AppHeader from './components/AppHeader';
import React, {useRef} from 'react';
import {elementScrollIntoViewPolyfill} from "seamless-scroll-polyfill";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import RequestPage from './components/pages/RequestPage';
import InterbankCallbackPage from './components/pages/InterbankCallbackPage';
import NotFoundPage from './components/pages/NotFoundPage';
import WrongDataClaimPage from './components/pages/WrongDataClaimPage';

elementScrollIntoViewPolyfill();

function App() {
    const location = useLocation();
    const bottomElRef = useRef(null);
    const topElRef = useRef(null);
    const scrollTop = () => {
        topElRef?.current?.scrollIntoView({behavior: 'smooth'});
    };
    const scrollBottom = () => {
        bottomElRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    return (
        <div className="App">
            <div ref={topElRef}/>
            <div className={styles.content}>
                <AppHeader/>
                    <Routes>
                        <Route path='/:requestId' element={<RequestPage scrollTop={scrollTop} scrollBottom={scrollBottom}/>} />
                        <Route path='/:requestId/wrong-data' element={<WrongDataClaimPage/>} />
                        <Route path='/oauth2/callback' element={<InterbankCallbackPage/>} />
                        <Route path='/not-found' element={<NotFoundPage/>} />
                        <Route path='*' element={<Navigate  to='/not-found' state={{from: location.pathname, fromFull: window.location.href}}/>} />
                    </Routes>
            </div>
            <div ref={bottomElRef}/>
        </div>
    );
}
export default App;
