import React from 'react';
import styles from './ErrorPopup.module.css';
import Button from './Button';
import Popup from './Popup';
import {ReactComponent as ErrorIcon} from '../assets/images/error-icon.svg';

const ValidationPopup = ({failedInputs, shown,triggerClose}) => {
    return (
        <Popup shown={shown} setShown={triggerClose} title={'Проверка не пройдена'}>
            <div className={styles.message}>Для продолжения требуется правильно заполнить данные.
            </div>
            <div className={styles.message}>
                Напротив каждого поля с ошибкой отображается символ <span className={styles.errorIconWrapper}><ErrorIcon/></span>.
                Нажмите на него, либо наведите курсор, чтобы увидеть описание ошибки.
            </div>
            {failedInputs ?
                <div className={styles.message}>
                    Проверьте следующие поля:
                    {Object.keys(failedInputs)?.map((field, index) => <div className={styles.failedInputName} key={index}>– {failedInputs[field]?.name}</div>)}
                </div>
            : ''}
            <Button filled={false} onClick={triggerClose} className={styles.actionButton}>Понятно</Button>
        </Popup>
    );
};

export default ValidationPopup;