import React, {useContext, useEffect, useState} from 'react';
import Button from './Button';
import LinkButton from './LinkButton';
import {UserInputDataContext} from '../context/user-input-data';
import {validateInputs, incomeStepInputs} from '../utils/inputs';
import StepContentHeader from './StepContentHeader';
import IncomeEditingForm from './IncomeEditingForm';
import {ApiContext} from '../context/api';
import ValidationPopup from './ValidationPopup';
import incomeSubstepTypes from '../types/income-substeps';
import {InternalStepStateContext} from '../context/internal-step-state-context';

const IncomeStep = ({onStepComplete, onStepBack}) => {
    const {internalStepState} = useContext(InternalStepStateContext);

    const subStepTitles = {
        [incomeSubstepTypes.FAMILY]: 'Семейное положение и имущество в собственности',
        [incomeSubstepTypes.JOB]: 'Место работы и доход',
        [incomeSubstepTypes.CONTACTS]: 'Контакты для связи',
    };

    const {submitUserInputData, dataLoading} = useContext(ApiContext);
    const {userInputData} = useContext(UserInputDataContext);

    const [currentSubStep, setCurrentSubStep] = useState(incomeSubstepTypes.FAMILY);
    const [failedValidationInputs, setFailedValidationInputs] = useState(null);
    const [validationPopupShown, setValidationPopupShown] = useState(false);

    const handleNextClick = async () => {
        const requiredSkipMap = {
            ownedProperty: !internalStepState.userOwnsProperty,
            ownedCarBrand: !internalStepState.userOwnsCar,
            ownedCarYear: !internalStepState.userOwnsCar,
            jobContractEndDate: !internalStepState.userIsContractWorker,
        };
        const incomeDataValidationResult = validateInputs(userInputData, incomeStepInputs[currentSubStep], requiredSkipMap);
        if(incomeDataValidationResult.status){
            switch (currentSubStep) {
                case incomeSubstepTypes.FAMILY:
                    setCurrentSubStep(incomeSubstepTypes.JOB);
                    break;
                case incomeSubstepTypes.JOB:
                    setCurrentSubStep(incomeSubstepTypes.CONTACTS);
                    break;
                case incomeSubstepTypes.CONTACTS:
                    const dataSubmitted = await submitUserInputData();
                    if(dataSubmitted){
                        onStepComplete();
                    }
                    break;
            }
        }else{
            setFailedValidationInputs(incomeDataValidationResult.failedInputs);
            setValidationPopupShown(true);
        }
    };

    const handlePrevClick = () => {
        switch (currentSubStep) {
            case incomeSubstepTypes.FAMILY:
                onStepBack();
                break;
            case incomeSubstepTypes.JOB:
                setCurrentSubStep(incomeSubstepTypes.FAMILY);
                break;
            case incomeSubstepTypes.CONTACTS:
                setCurrentSubStep(incomeSubstepTypes.JOB);
                break;
        }
    };

    return (
        <div>
            <ValidationPopup
                failedInputs={failedValidationInputs}
                shown={validationPopupShown}
                triggerClose={() => setValidationPopupShown(false)}>
            </ValidationPopup>
            <StepContentHeader>{subStepTitles[currentSubStep || '']}</StepContentHeader>
            <IncomeEditingForm failedInputs={failedValidationInputs} currentSubStep={currentSubStep}/>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <Button filled={false} style={{margin: '40px auto 20px auto'}} onClick={handleNextClick} disabled={dataLoading}>{!dataLoading ? 'Подтвердить данные' : 'Пожалуйста, подождите...'}</Button>
                <LinkButton onClick={handlePrevClick} style={{marginBottom: '20px'}} disabled={dataLoading}> ← Вернуться назад</LinkButton>
            </div>
        </div>
    );
};

export default IncomeStep;