import axios, {AxiosError} from 'axios';
export default class Api{
    constructor(requestId) {
        this.apiRoot = process.env.REACT_APP_API_ROOT || '/api';
        this.requestId = requestId;
        this.isLoading = false;
        this.errors = {
            [AxiosError.ERR_NETWORK]: 'Проблемы с сетевым подключением либо доступностью сервера',
            [AxiosError.ETIMEDOUT]: 'Превышено время ожидания ответа от сервера',
            [AxiosError.ERR_FR_TOO_MANY_REDIRECTS]: 'Слишком много перенаправлений',
            [AxiosError.ERR_CANCELED]: 'Запрос отменен',
            [AxiosError.ECONNABORTED]: 'Соединение разорвано',
            [AxiosError.ERR_BAD_RESPONSE]: 'Ошибка сервера',
        };
    }
    getRequest(requestId){
        return this.sendHttpRequest({
            method: 'GET',
            url: `${this.apiRoot}/request/${this.requestId}`,
        });
    }

    updateUserInput(userInput){
        return this.sendHttpRequest({
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            url: `${this.apiRoot}/request/${this.requestId}/user-input`,
            data: this.prepareEmptyValues(userInput)
        });
    }
    getDocumentFiles(){
        return this.sendHttpRequest({
            method: 'PUT',
            url: `${this.apiRoot}/request/${this.requestId}/document-files`,
        });
    }
    requestVerificationCode(){
        return this.sendHttpRequest({
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            url: `${this.apiRoot}/request/verification-code`,
            data: {requestId: this.requestId}
        });
    }
    signRequest(verificationCode, adsAllowed, acceptedFileIds){
        return this.sendHttpRequest({
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            url: `${this.apiRoot}/request/sign`,
            data: {verificationCode, adsAllowed, acceptedFileIds, requestId: this.requestId}
        });
    }
    performInterbankCallback(code){
        return this.sendHttpRequest({
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            url: `${this.apiRoot}/request/interbank-callback`,
            data: {code, requestId: this.requestId}
        });
    }
    async sendHttpRequest(config){
        let result = {data: null, error: null, statusCode: null};
        try{
            this.isLoading = true;
            const response = (await axios(config));
            result.data = response.data;
            result.statusCode = response?.status;
        }catch (error){
            console.log(error);
            const errorName = error.response?.data?.error;
            const errorDesc = error.response?.data?.message;
            if(errorDesc){
                result.error = errorDesc;
            }else if(this.errors[error.code]){
                result.error = `${errorName ? errorName + ' ' : ''}${this.errors[error.code]}`;
            }else{
                result.error = `Возникла ошибка: ${error.message}`;
            }
            result.statusCode = error.response?.status;
        }finally {
            this.isLoading = false;
        }
        return result;
    }

    prepareEmptyValues(data){
        data = {...data};
        Object.keys(data).forEach(key => {
            if(data[key] === ''){
                data[key] = null;
            }
        });
        return data;
    }

}