import React from 'react';
import styles from './StepContentHeader.module.css';
const StepContentHeader = ({children}) => {
    return (
        <h4 className={styles.title}>
            {children}
        </h4>
    );
};

export default StepContentHeader;