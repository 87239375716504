import React, {useEffect, useState} from 'react';
import styles from './Checkbox.module.css';
import {ReactComponent as CheckMark} from '../assets/images/check.svg';

const Checkbox = ({label, style, value, name, onChangeCallback, disabled}) => {
    const handleChange = ({name, value}) => {
        if(onChangeCallback && name !== undefined){
            onChangeCallback({name: name, value: value});
        }
    };
    const handleKeyUp = (event, value) => {
        if(event.key === 'Enter')
            return !disabled && handleChange({name, value});
    };
    return (
        <div onClick={() => handleChange({name, value: !value})}
             onKeyUp={(e) => handleKeyUp(e, !value)}
             className={`${styles.checkbox} ${value ? styles.checked : ''} ${disabled ? styles.disabled : ''}`} tabIndex={0} style={style}>
            <div className={styles.checkMarkContainer}>
                <CheckMark/>
            </div>
            {label}
        </div>
    );
};

export default Checkbox;