import incomeTypes from '../types/income';
import incomeSubStepTypes from '../types/income-substeps';
import educationTypes from '../types/education';
import maritalStatusTypes from '../types/marital-status';
import ownedPropertyTypes from '../types/owned-property';
import {datePattern, phonePattern} from './patterns';
import {stripPhoneFormatting} from './format';

export const MAX_INPUT_LENGTH = 1024;
export const MAX_NUMERIC_VALUE = Number.MAX_SAFE_INTEGER;

export const validateInputs = (data, inputs, requiredSkipMap) => {
    let result = {status: true, failedInputs: {}};
    for (let fieldName of Object.keys(inputs)){
        if(inputs[fieldName].required && !requiredSkipMap?.[fieldName]){
            if(!data[fieldName]?.toString()?.trim()){
                result.status = false;
                result.failedInputs[fieldName] = {message: 'Обязательное к заполнению поле', name: inputs[fieldName]?.label};
            }

            if(inputs[fieldName].type === 'int' || inputs[fieldName].type === 'float' ){
                if(!parseInt(data[fieldName])){
                    result.status = false;
                    result.failedInputs[fieldName] = {message: 'Обязательное к заполнению поле не может иметь значение 0', name: inputs[fieldName]?.label};
                }
            }
        }
        if(inputs[fieldName].type === 'phone' && data[fieldName]){
            const cleanValue = `+${stripPhoneFormatting(data[fieldName])}`;
            if(!cleanValue?.match(phonePattern)){
                result.status = false;
                result.failedInputs[fieldName] = {message: 'Телефон должен соответствовать формату +375...', name: inputs[fieldName]?.label};
            }
        }
        if(inputs[fieldName].type === 'date' && data[fieldName]){
            if(!data[fieldName]?.match(datePattern)){
                result.status = false;
                result.failedInputs[fieldName] = {message: 'Дата должна быть корректной и соответсвовать формату дд.мм.гггг', name: inputs[fieldName]?.label};
            }
        }
        const maxValue = inputs[fieldName].maxValue || MAX_NUMERIC_VALUE;
        if(inputs[fieldName].type === 'float' && data[fieldName]){
            if(parseFloat(data[fieldName]).toFixed(2) > maxValue){
                result.status = false;
                result.failedInputs[fieldName] = {message: `Значение не должно превышать ${maxValue}`, name: inputs[fieldName]?.label};
            }
        }
        const maxLength = inputs[fieldName].maxLength || MAX_INPUT_LENGTH;
        if(data[fieldName]?.length >  maxLength){
            result.status = false;
            result.failedInputs[fieldName] =
                {message: `Длина значения не должна превышать ${inputs[fieldName].maxLength || MAX_INPUT_LENGTH} симв.`, name: inputs[fieldName]?.label};
        }
        const minLength = inputs[fieldName].minLength || null;
        if(minLength && data[fieldName]?.length <  minLength){
            result.status = false;
            result.failedInputs[fieldName] =
                {message: `Длина значения не должна быть меньше ${inputs[fieldName].minLength} симв.`, name: inputs[fieldName]?.label};
        }
    }
    return result;
};
const addressFieldNames = ['addressCountry', 'addressRegion', 'addressDistrict',
    'addressUnitaryArea', 'addressLocalityType',
    'addressLocality', 'addressStreetType', 'addressStreet',
    'addressHouse', 'addressBuilding', 'addressApartment', 'addressPostalCode'];

const addressFields = addressFieldNames.reduce((acc, field) => {
    const trimmedField = field.slice(1);
    return {
        ...acc,
        [field]: {plain: field, living: `livingA${trimmedField}`, reg: `regA${trimmedField}`}
    };
}, {});
export const addressStepInputs = {
    [addressFields.addressCountry.living]: {label: 'Страна', required: true,  maxLength: 64},
    [addressFields.addressRegion.living]: {label: 'Область',  maxLength: 64},
    [addressFields.addressDistrict.living]: {label: 'Район',  maxLength: 64},
    [addressFields.addressUnitaryArea.living]: {label: 'Сельсовет',  maxLength: 64},
    [addressFields.addressLocalityType.living]: {label: 'Тип населенного пункта', required: true,  maxLength: 32},
    [addressFields.addressLocality.living]: {label: 'Населенный пункт', required: true,  maxLength: 64},
    [addressFields.addressStreetType.living]: {label: 'Тип улицы', required: true,  maxLength: 32},
    [addressFields.addressStreet.living]: {label: 'Улица', required: true,  maxLength: 64},
    [addressFields.addressHouse.living]: {label: 'Дом', required: true,  maxLength: 16},
    [addressFields.addressBuilding.living]: {label: 'Строение/корпус',  maxLength: 16},
    [addressFields.addressApartment.living]: {label: 'Квартира',  maxLength: 16},
    [addressFields.addressPostalCode.living]: {label: 'Индекс', required: true, type: 'int', minLength: 6, maxLength: 6},
};
export const incomeStepInputs = {
    [incomeSubStepTypes.FAMILY]: {
        maritalStatus: {label: 'Семейное положение', type: 'select', optionTypes: maritalStatusTypes, required: true},
        drivingExperience: {label: 'Водительский стаж, лет', type: 'int', minLength: 1, maxLength: 3},
        ownedProperty: {label: 'Недвижимое имущество в собственности', type: 'select', optionTypes: ownedPropertyTypes, isMultiSelect: true, required: true},
        ownedCarBrand: {label: 'Марка авто', required: true,  maxLength: 64},
        ownedCarYear: {label: 'Год выпуска авто', required: true, type: 'int', minLength: 4, maxLength: 4},
    },
    [incomeSubStepTypes.JOB]: {
        incomeMainType: {label: 'Тип должности', type: 'select', optionTypes: incomeTypes, required: true},
        jobPlace: {label: 'Место работы (наименование организации)', required: true,  maxLength: 256},
        jobCompanyAddress: {label: 'Адрес организации', required: true,  maxLength: 256},
        jobPhoneNumber: {label: 'Рабочий номер телефона (отдел кадров или бухгалтерия)', required: true, type: 'phone'},
        jobTitle: {label: 'Должность', required: true,  maxLength: 128},
        jobStartDate: {label: 'Дата начала работы', type: 'date', required: true},
        jobContractEndDate: {label: 'Дата окончания контракта (трудового договора)', type: 'date', required: true},
        incomeMainAmount: {label: 'Сумма основного дохода, бел.руб.', required: true, type: 'float', maxValue: 9999999999.99, maxLength: 13},
        spouseIncomeMainAmount: {label: 'Доход супруга по месту основной работы, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        incomePartTimeAmount: {label: 'Доход по месту работы по совместительству, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        incomeContractsAmount: {label: 'Доход по договорам подряда, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        pensionOrOtherIncomeAmount: {label: 'Пенсия/иное, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        incomeAdditionalSource: {label: 'Источник дополнительного дохода',  maxLength: 64},
        incomeAdditionalAmount: {label: 'Сумма дополнительного дохода, бел.руб.', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        totalWorkingExperience: {label: 'Общий стаж работы, лет', required: true, type: 'int', minLength: 1, maxLength: 4},
        higherEducation: {label: 'Ваше образование', type: 'select', optionTypes: educationTypes, required: true},
        dependentsAmount: {label: 'Количество иждивенцев', type: 'int', minLength: 1, maxLength: 3},
        loanPaymentsAmount: {label: 'Сумма платежей по кредитам, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        installmentPaymentsAmount: {label: 'Сумма платежей по рассрочкам, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        executiveOrderPaymentsAmount: {label: 'Сумма платежей по исполнительным листам, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
        alimonyPaymentsAmount: {label: 'Сумма платежей по алиментам, бел.руб', type: 'float', maxValue: 9999999999.99, maxLength: 13},
    },
    [incomeSubStepTypes.CONTACTS]: {
        emergencyPhoneNumber: {label: 'Телефон для экстренной связи (доверенное лицо)', required: true, type: 'phone'},
        additionalPhoneNumber: {label: 'Дополнительный номер телефона', type: 'phone'},
        email: {label: 'Email', maxLength: 1024},
    },

};
export const personalDataStepInputs = {
    familyNamePrevious: {label: 'Предыдущая фамилия, если менялась',  maxLength: 64},
};
export const checkRegAndLivingAddressEquality = (interbankData, userInputData) => {
    return checkAddressEquality({obj: interbankData, type: 'reg'}, {obj: userInputData, type: 'living'});
};
export const generateLivingAddrFromOriginal = (interbankData, type) => {
    const newLivingAddr = {};
    for (let fieldName of addressFieldNames){
        newLivingAddr[addressFields[fieldName].living] = interbankData[addressFields[fieldName][type]] || '';
    }
    return newLivingAddr;
};
export const checkAddressEquality = (firstAddr, secondAddr) => {
    let areEqual = true;
    for (let fieldName of addressFieldNames){
        const firstAddrVal = firstAddr.obj[addressFields[fieldName][firstAddr.type]]?.trim()?.toLowerCase();
        const secondAddrVal = secondAddr.obj[addressFields[fieldName][secondAddr.type]]?.trim().toLowerCase();
        if(!((firstAddrVal === secondAddrVal) || (!firstAddrVal && !secondAddrVal))){
            areEqual = false;
            break;
        }
    }
    return areEqual;
};

const inputModificationModes = {
    PICK: 'pick',
    DROP: 'drop',
};

const modifyInputs = (inputs, inputNamesToPick, mode) => {
    const inputsCopy = { ...inputs };
    for (const inputName in inputsCopy) {
        const isListed = inputNamesToPick.includes(inputName);
        const shouldDrop = ((mode === inputModificationModes.DROP) && isListed)
                            || ((mode === inputModificationModes.PICK) && !isListed);
        if (shouldDrop) {
            delete inputsCopy[inputName];
        }
    }
    return inputsCopy;
};

export const dropInputs =(inputs, inputNamesToPick) => {
    return modifyInputs(inputs, inputNamesToPick, inputModificationModes.DROP);
};

export const pickInputs =(inputs, inputNamesToPick) => {
    return modifyInputs(inputs, inputNamesToPick, inputModificationModes.PICK);
};