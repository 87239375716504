import React from 'react';
import styles from './FatalError.module.css';
import Button from './Button';
import ErrorAnim from '../assets/animations/dog-error.json';
import Lottie from 'lottie-react';

const FatalError = ({customText, customButton}) => {
    const refreshPage = () => {
        window.location.reload();
    };
    return (
        <div className={styles.container}>

            <Lottie animationData={ErrorAnim} loop={true} style={{height: '30vh'}}/>
            <div className={styles.errorTitle}>Что-то пошло не так...</div>
            <div className={styles.errorText}>
                {customText ? customText
                    : <>
                        <div>
                            Возникла ошибка, которая не позволила продолжить работу приложения.
                        </div>
                        <div className={styles.errorText}>
                            Перезагрузите страницу сейчас, либо повторите попытку позже.
                        </div>
                    </>
                }
            </div>
            {customButton ? customButton : <Button filled={false} onClick={refreshPage}>Обновить страницу</Button>}
        </div>
    );
};

export default FatalError;