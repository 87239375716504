import React, {useContext} from 'react';
import {ClientDataContext} from '../context/client-data';
import styles from './ClientNameConcurrencyHint.module.css';
const ClientNameConcurrencyHint = ({style}) => {
    const {clientData} = useContext(ClientDataContext);
    const originalName = clientData?.givenName?.toUpperCase()?.trim();
    const originalSurname = clientData?.familyName?.toUpperCase()?.trim();
    const fetchedName = clientData?.interbankData?.givenNameRu?.toUpperCase()?.trim();
    const fetchedSurname = clientData?.interbankData?.familyNameRu?.toUpperCase()?.trim();
    const shouldBeRendered = originalName !== fetchedName || originalSurname !== fetchedSurname;
    return ( shouldBeRendered ?
        <div className={styles.container} style={style}>
            <div>Уважаемый клиент!</div>
            <div>Обнаружено несовпадение имени в заявке (<span className={styles.name}>{originalName} {originalSurname}</span>)
                с данными из МСИ (<span className={styles.name}>{fetchedName} {fetchedSurname}</span>).</div>
            <div>Аутентифицироваться в МСИ должен тот клиент, который запросил заявку.</div>
            <div>Проигнорируйте это сообщение, если оно вызвано опечаткой или неполнотой данных в заявке. В противном случае запросите заявку на корректное имя.</div>
        </div> : ''
    );
};

export default ClientNameConcurrencyHint;