import React, {useContext, useEffect, useState} from 'react';
import {ClientDataContext} from '../context/client-data';
import RadioInput from './RadioInput';
import {UserInputDataContext} from '../context/user-input-data';
import {addressStepInputs, checkRegAndLivingAddressEquality, generateLivingAddrFromOriginal} from '../utils/inputs';
import FormInputSet from './FormInputSet';
import {InternalStepStateContext} from '../context/internal-step-state-context';
const AddressEditingForm = ({failedInputs}) => {
    const {clientData} = useContext(ClientDataContext);
    const {userInputData, setUserInputData} = useContext(UserInputDataContext);
    const {internalStepState, setInternalStepState} = useContext(InternalStepStateContext);
    const isEqualToRegAddress = internalStepState.isEqualToRegAddress;
    const setIsEqualToRegAddress = (value) => {
        setInternalStepState({...internalStepState, isEqualToRegAddress: value})
    }
    useEffect(() => {
        const areEqual = checkRegAndLivingAddressEquality(clientData?.interbankData, userInputData);
        setIsEqualToRegAddress(areEqual);
    }, [userInputData]);
    const handleAddressEqualityChangeFromRadio = ({value}) => {
        if(value === isEqualToRegAddress)
            return;
        setIsEqualToRegAddress(value);
        if(value){
            setUserInputData(prev => ({...prev, ...generateLivingAddrFromOriginal(clientData?.interbankData, 'reg')}));
        }else{
            setUserInputData(prev => ({...prev, ...generateLivingAddrFromOriginal(clientData?.interbankData, 'living')}));
        }
    };
    return (
        <FormInputSet
            inputGroups={[{
                inputs: addressStepInputs,
                groupChildren: <RadioInput
                                    value={isEqualToRegAddress}
                                    label={'Адрес проживания совпадает с адресом регистрации?'}
                                    name={'equalitySwitch'}
                                    onChangeCallback={handleAddressEqualityChangeFromRadio}/>}]}
            failedInputs={failedInputs}/>
    );
};

export default AddressEditingForm;