import React from 'react';
import styles from './LinkButton.module.css';
const LinkButton = ({children, onClick, style, disabled}) => {
    return (
        <button onClick={onClick} style={style} className={styles.linkButton} disabled={disabled}>
            {children}
        </button>
    );
};

export default LinkButton;