import React from 'react';
import styles from './DocumentSigningHint.module.css';

const DocumentSigningHint = ({style, requiredDocs}) => {
    const allRequiredDocsAreSigned = requiredDocs?.every(d => d.accepted);

    return (
        <div className={`${styles.container} ${allRequiredDocsAreSigned? styles.allRequiredSigned : ''}`} style={style}>
            <div>Уважаемый клиент!</div>
            {!allRequiredDocsAreSigned &&
                <>
                    <div>Для оформления онлайн-заявки необходимо подписать согласия:</div>
                    <div>
                        {requiredDocs?.filter(d => !d.isRequiredForPartialSign).map(d => <div>
                            {d.accepted ? '✅' : '❌'} {d.name}
                        </div>)}
                    </div>
                    <div>Если Вы продолжите без предоставления всех обязательных согласий, Вы сможете подписать их в офисе компании.</div>
                </>
            }
            {allRequiredDocsAreSigned &&
                <div>
                    Вы собираетесь подписать все необходимые для оформления онлайн-заявки документы.
                </div>
            }
        </div>
    );
};

export default DocumentSigningHint;