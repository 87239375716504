import React, {useContext, useState} from 'react';
import styles from './PersonalDataReview.module.css';
import {useNavigate} from 'react-router-dom';
import {ClientDataContext} from '../context/client-data';
import Input from './Input';
import {formatPhone} from '../utils/format';
import RadioInput from './RadioInput';
import gender from '../types/gender';
import Button from './Button';
import StepContentHeader from './StepContentHeader';
import {UserInputDataContext} from '../context/user-input-data';
import {personalDataStepInputs, validateInputs} from '../utils/inputs';
import ValidationPopup from './ValidationPopup';
import ClientNameConcurrencyHint from './ClientNameConcurrencyHint';
import LinkButton from './LinkButton';
const PersonalDataReview = ({onStepComplete}) => {
    const navigate = useNavigate();
    const {userInputData, setUserInputData} = useContext(UserInputDataContext);
    const genderOptions = Object.keys(gender).map(key => { return {name: gender[key], value: key}})
    const {clientData} = useContext(ClientDataContext);
    const [failedValidationInputs, setFailedValidationInputs] = useState(null);
    const [validationPopupShown, setValidationPopupShown] = useState(false);
    const handleInputChange = ({name, value}) => {
        if(failedValidationInputs?.[name]){
            delete failedValidationInputs[name];
        }
        setUserInputData(prev => {
            let updatedData = {...prev};
            updatedData[name] = value;
            return updatedData;
        });
    }
    const handleNextClick = () => {
        const validationResult = validateInputs(userInputData, personalDataStepInputs);
        if(validationResult.status){
            onStepComplete();
        }else{
            setFailedValidationInputs(validationResult.failedInputs);
            setValidationPopupShown(true);
        }
    };
    return (
        <>
            <StepContentHeader>
                Личные данные и документ удостоверяющий личность
            </StepContentHeader>
            <ValidationPopup failedInputs={failedValidationInputs} shown={validationPopupShown} triggerClose={() => setValidationPopupShown(false)}></ValidationPopup>
            <ClientNameConcurrencyHint style={{marginBottom: '20px'}}/>
            <div className={styles.container}>
                <Input value={clientData?.interbankData?.familyNameRu} label={'Фамилия'} disabled/>
                <Input value={clientData?.interbankData?.givenNameRu} label={'Имя'} disabled/>
                <Input value={clientData?.interbankData?.middleNameRu} label={'Отчество'} disabled/>

                <Input
                       value={userInputData !== null ? (userInputData['familyNamePrevious'] || '') : ''}
                       label={personalDataStepInputs.familyNamePrevious.label}
                       name='familyNamePrevious'
                       required={personalDataStepInputs.familyNamePrevious.required || false}
                       onChangeCallback={handleInputChange}
                       error={failedValidationInputs?.['familyNamePrevious']?.message}
                       type='text'
                       maxLength={personalDataStepInputs.familyNamePrevious.maxLength}
                />

                <Input value={clientData?.interbankData?.birthDate} label={'Дата рождения'} disabled/>
                <RadioInput options={genderOptions} value={clientData?.interbankData?.sex} label={'Пол'} disabled/>
                <Input value={`${clientData?.interbankData?.idDocumentSeries} ${clientData?.interbankData?.idDocumentNumber}`} label={'Серия и номер документа'} disabled/>
                <Input value={clientData?.interbankData?.nationalIdNumber} label={'Идентификационный номер'} disabled/>
                <Input value={clientData?.interbankData?.idDocumentIssued} label={'Дата выдачи'} disabled/>
                <Input value={clientData?.interbankData?.idDocumentExpires} label={'Срок действия'} disabled/>
                <Input value={clientData?.interbankData?.idDocumentAuthority} label={'Орган выдавший документ'} disabled/>
                <RadioInput value={clientData?.interbankData?.isResident} label={'Вы резидент Республики Беларусь?'} disabled/>
                <RadioInput value={clientData?.interbankData?.isResidentUS} label={'Вы налоговый резидент США?'} disabled/>
            </div>
            <div className={styles.buttonsContainer}>
                <LinkButton onClick={() => navigate('wrong-data')} style={{marginTop: '40px'}}>Данные неверны</LinkButton>
                <Button filled={false} style={{margin: '20px auto 40px auto'}} onClick={handleNextClick}>Подтвердить данные</Button>
            </div>
        </>
    );
};

export default PersonalDataReview;