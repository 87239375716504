import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './AddressStep.module.css';
import Button from './Button';
import LinkButton from './LinkButton';
import Input from './Input';
import {ClientDataContext} from '../context/client-data';
import StepContentHeader from './StepContentHeader';
import AddressEditingForm from './AddressEditingForm';
import {validateInputs, addressStepInputs} from '../utils/inputs';
import {UserInputDataContext} from '../context/user-input-data';
import {InternalStepStateContext} from '../context/internal-step-state-context';
import ValidationPopup from './ValidationPopup';
const AddressStep = ({onStepComplete, onStepBack}) => {
    const navigate = useNavigate();
    const {clientData} = useContext(ClientDataContext);
    const {userInputData} = useContext(UserInputDataContext);
    const {internalStepState, setInternalStepState} = useContext(InternalStepStateContext);
    const regAddressReviewed = internalStepState.regAddressReviewed;
    const setRegAddressReviewed = (value) => {
        setInternalStepState({...internalStepState, regAddressReviewed: value})
    }
    const [failedValidationInputs, setFailedValidationInputs] = useState(null);
    const [validationPopupShown, setValidationPopupShown] = useState(false);
    const handleNextClick = () => {
        if(regAddressReviewed){
            const addressDataValidationResult = validateInputs(userInputData, addressStepInputs);
            if(addressDataValidationResult.status){
                onStepComplete();
            }else{
                setFailedValidationInputs(addressDataValidationResult.failedInputs);
                setValidationPopupShown(true);
            }
        } else
            setRegAddressReviewed(true);
    };
    const handlePrevClick = () => {
        if(regAddressReviewed)
            setRegAddressReviewed(false);
        else
            onStepBack();
    };
    return (
        <div>
            <StepContentHeader>{!regAddressReviewed ? 'Адрес регистрации' : 'Адрес проживания'}</StepContentHeader>
            <ValidationPopup failedInputs={failedValidationInputs} shown={validationPopupShown} triggerClose={() => setValidationPopupShown(false)}></ValidationPopup>
            <div className={styles.inputsContainer}>
                {!regAddressReviewed ?
                    <>
                        <Input value={clientData?.interbankData?.regAddressLocality} label={'Населенный пункт'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressStreetType} label={'Тип улицы'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressStreet} label={'Улица'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressHouse} label={'Дом'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressBuilding} label={'Строение/корпус'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressApartment} label={'Квартира'} disabled/>
                        <Input value={clientData?.interbankData?.regAddressPostalCode} label={'Индекс'} disabled/>
                    </>
                    :
                    <AddressEditingForm failedInputs={failedValidationInputs}/>
                }
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                {!regAddressReviewed && <LinkButton onClick={() => navigate('wrong-data')} style={{marginTop: '40px'}}>Данные неверны</LinkButton>}
                <Button filled={false} style={{margin: !regAddressReviewed ? '20px auto 20px auto' : '40px auto 20px auto'}} onClick={handleNextClick}>Подтвердить данные</Button>
                <LinkButton onClick={handlePrevClick} style={{marginBottom: '20px'}}> ← Вернуться назад</LinkButton>
            </div>
        </div>
    );
};

export default AddressStep;