import React, {useState, useRef, useEffect} from 'react';
import styles from './SegmentedInput.module.css';

const SegmentedInput = ({length = 4, onChangeCallback, excludePattern, inputMode = 'numeric', triggerFocusInputIndicator}) => {
    const input = useRef();
    const [value, setValue] = useState('');
    const [focused, setFocused] = useState(false);
    const handleChange = (event) => {
        setValue((value + event.target.value).slice(0, length));
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Backspace'){
            setValue(value.slice(0, -1));
        }
    };
    useEffect(() => {
        if(onChangeCallback){
            onChangeCallback(value);
        }
    }, [value]);
    useEffect(() => {
        if(triggerFocusInputIndicator !== 0){
            input.current.focus();
        }
    }, [triggerFocusInputIndicator]);
    const maskByPattern = (event) => {
        if(excludePattern){
            event.target.value = event.target.value.replace(excludePattern, '');
        }
    };
    return (
        <div className={styles.wrapper} onClick={()=> input.current.focus()}>
            <div className={styles.cellsContainer}>
                {Array(length).fill(null).map((_, index) => {
                    const isCurrentTarget = index === value.length || (index === value.length - 1 && value.length === length);
                    return <div key={index}
                                className={`${styles.cell} ${isCurrentTarget && focused ? styles.focused : ''} ${value[index] ? styles.filled : ''}`} >
                        <span className={styles.cellValue}>{value[index]}</span>
                    </div>;
                })}
            </div>
            <input ref={input}
                   className={`${styles.input} ${value.length === length ? styles.hidden : ''}`}
                   type='text' inputMode={inputMode}
                   onInput={maskByPattern}
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}
                   onFocus={()=>setFocused(true)}
                   onBlur={()=>setFocused(false)}
                   value=''
                   style={{left: `${value.length * 46 + 3}px`}}
                   autoComplete='one-time-code'
            />
        </div>
    );
};

export default SegmentedInput;