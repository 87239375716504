import {formatPhone, stripPhoneFormatting} from './format';

export const populateUserInputData = (requestData) => {
    return {
        maritalStatus: requestData?.maritalStatus,
        incomeAdditionalAmount: requestData?.incomeAdditionalAmount,
        incomeAdditionalSource: requestData?.incomeAdditionalSource,
        incomeMainAmount: requestData?.incomeMainAmount,
        incomeMainType: requestData?.incomeMainType,
        incomeMainTypeLabel: requestData?.incomeMainTypeLabel,
        jobPlace: requestData?.jobPlace,
        jobStartDate: requestData?.jobStartDate,
        jobTitle: requestData?.jobTitle,
        ownedProperty: requestData?.ownedProperty,
        drivingExperience: requestData?.drivingExperience,
        ownedCarBrand: requestData?.ownedCarBrand,
        ownedCarYear: requestData?.ownedCarYear,
        jobCompanyAddress: requestData?.jobCompanyAddress,
        jobPhoneNumber: formatPhone(requestData?.jobPhoneNumber || ''),
        jobContractEndDate: requestData?.jobContractEndDate,
        spouseIncomeMainAmount: requestData?.spouseIncomeMainAmount,
        incomePartTimeAmount: requestData?.incomePartTimeAmount,
        incomeContractsAmount: requestData?.incomeContractsAmount,
        pensionOrOtherIncomeAmount: requestData?.pensionOrOtherIncomeAmount,
        totalWorkingExperience: requestData?.totalWorkingExperience,
        higherEducation: requestData?.higherEducation,
        emergencyPhoneNumber: formatPhone(requestData?.emergencyPhoneNumber || ''),
        adsAllowed: false,
        livingAddressApartment: requestData?.interbankData?.livingAddressApartment,
        livingAddressBuilding: requestData?.interbankData?.livingAddressBuilding,
        livingAddressCountry: requestData?.interbankData?.livingAddressCountry,
        livingAddressDistrict: requestData?.interbankData?.livingAddressDistrict,
        livingAddressHouse: requestData?.interbankData?.livingAddressHouse,
        livingAddressLocality: requestData?.interbankData?.livingAddressLocality,
        livingAddressLocalityType: requestData?.interbankData?.livingAddressLocalityType ,
        livingAddressPostalCode: requestData?.interbankData?.livingAddressPostalCode ,
        livingAddressRegion: requestData?.interbankData?.livingAddressRegion ,
        livingAddressStreet: requestData?.interbankData?.livingAddressStreet ,
        livingAddressStreetType: requestData?.interbankData?.livingAddressStreetType,
        livingAddressUnitaryArea: requestData?.interbankData?.livingAddressUnitaryArea,
    }
};

export const prepareUserInputData = (userInputData) => {
  const dataCopy = {...userInputData};

  if (dataCopy.emergencyPhoneNumber) {
      dataCopy.emergencyPhoneNumber = '+' + stripPhoneFormatting(dataCopy.emergencyPhoneNumber);
  }

  if (dataCopy.jobPhoneNumber) {
      dataCopy.jobPhoneNumber = '+' + stripPhoneFormatting(dataCopy.jobPhoneNumber);
  }

  if (dataCopy.additionalPhoneNumber) {
      dataCopy.additionalPhoneNumber = '+' + stripPhoneFormatting(dataCopy.additionalPhoneNumber);
  }

  return dataCopy;
};